<template>
    <section>
        <modal ref="modalMapa" titulo="Ubicar vendedor en" tamano="modal-xl" icon="location" no-aceptar no-cancelar>
            <mapa-google
            ref="googleMap"
            :desactivar-mapa="false"
            icono-central="/img/icons/tendero-point-map.svg"
            :dragable="true"
            />
        </modal>
    </section>
</template>

<script>
import Produccion from "~/services/tiendas/produccion";

export default {
    data(){
        return {
        }
    },

    methods: {
        async toggle(){
            await this.iniciarMapa()
            this.$refs.modalMapa.toggle()
        },
        async iniciarMapa(){
            const { data } = await Produccion.getDatosMapaLeechero(this.$route.params.id_tienda)
            const contentString = (el) => {

                const filtered = [el.direccion, el.direccion_anexo, el.barrio].filter(el => el)
                const direccion = _.join(filtered, ' - ')
                const fecha = this.formatearFecha(el.created_at)
                return `
                    <div style="border-radius:12px;position:relative;text-align:center;">
                        <img src="${el.imagen}" width="120px" height="120px" style="border-radius:50%;object-fit:cover; border:1px #d1d1d1 solid" />
                    </div>
                    <p style="margin-top: 10px;font-size:14px;font-weight: bold;text-align:center;"><b>${el.propietario_nombre}</b></p>
                    <div class="text-left mx-3">
                        <div class="row mx-0 my-2">
                            <span class="icon-   f-18" /> <span class="f-14">${el.direccion}</span>
                        </div>
                        <div class="row mx-0 my-2">
                            <span class="icon-warehouse f-18" /> <span class="f-14">Cedis: ${el.cedis_nombre}</span>
                        </div>
                        <div class="row mx-0 my-2">
                            <span class="icon-calendar-check f-18" /> <span class="f-14">Registrado desde: ${fecha}</span>
                        </div>
                    </div>
                `;
            }
            let tiendas = data.map(e => ({
                lat: e.latitud,
                lng: e.longitud,
                icon: '/img/icons/tendero.svg',
                info: contentString(e)
            }))
            tiendas[0].icon = '/img/icons/tendero-point-map.svg'
            this.$refs.googleMap.initMapMarkerClusterer(tiendas)
        },
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px;
}
.br-15{
    border-radius: 15px;
}
</style>
